export enum LocalLoginStatusEnum {
	Ok = 10,
	ProfileRequired = 11,
	GenericError = 20,
	WrongCredentials = 21,
}

export enum CreateLoginOtpStatusEnum {
	Ok = 10,
	GenericError = 20,
}

///////////////////////////////////////

export interface CreateLoginOtpRequest {
	email: string;
}

export interface CreateLoginOtpResponse {
	statusId: CreateLoginOtpStatusEnum;
	blockingTtlSeconds: number;
}

export interface ProcessLocalLoginRequest {
	email: string;
	password: string;
}

export interface ProcessLocalLoginResponse {
	statusId: LocalLoginStatusEnum;
	pendingProfileToken?: string;
}

export interface CompleteLocalRegistrationRequest {
	email: string;
	firstName: string;
	lastName: string;
	pendingProfileToken: string
}

export interface UpdateUserProfileRequest {
	firstName: string;
	lastName: string;
}
