import { ChangeDetectionStrategy, Component, inject, Input, output } from '@angular/core';
import { IConfigurationService } from '../../../../core/services/configuration/configuration.service';
import { combineLatest, map, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

type ExternalUrls = {
	google: string;
	facebook: string;
}

@Component({
	selector: 'app-external-login',
	standalone: true,
	imports: [
		NgIf,
		AsyncPipe,
		MatButtonModule,
		MatIcon
	],
	templateUrl: './external-login.component.html',
	styleUrl: './external-login.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalLoginComponent {

	private readonly _configService = inject(IConfigurationService);
	private readonly _route = inject(ActivatedRoute);

	@Input() disabled = false;

	login = output();

	urls$: Observable<ExternalUrls>;

	constructor() {
		this.urls$ = combineLatest([
			this._configService.getConfiguration(),
			this._route.queryParamMap
		]).pipe(
			map(([c, q]) => ({ c, q })),
			map(v => {
				let params = new HttpParams();
				const return_url = v.q.get(v.c.returnUrlParamName);
				const lang = v.q.get("lang");
				if (return_url) {
					params = params.set(v.c.returnUrlParamName, return_url);
				}
				if (lang) {
					params = params.set("lang", lang);
				}
				return <ExternalUrls>{
					google: `${v.c.endpoints.loginByGoogle}?${params.toString()}`,
					facebook: `${v.c.endpoints.loginByFacebook}?${params.toString()}`,
				};
			})
		);
	}

	onLogin() {
		this.login.emit();
	}

}
