import { inject } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

export const addSvgIcons = () => {
	const matIconRegistry = inject(MatIconRegistry);
	const domSanitizer = inject(DomSanitizer);

	matIconRegistry.addSvgIcon(
		"google",
		domSanitizer.bypassSecurityTrustResourceUrl("/icons/google-01.svg")
	);

	matIconRegistry.addSvgIcon(
		"facebook",
		domSanitizer.bypassSecurityTrustResourceUrl("/icons/facebook-01.svg")
	);

	matIconRegistry.addSvgIcon(
		"apple",
		domSanitizer.bypassSecurityTrustResourceUrl("/icons/apple-01.svg")
	);
};
