import { ChangeDetectionStrategy, Component, computed, effect, inject, OnDestroy, Signal, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutoFocusDirective } from '../../../../shared/directives/auto-focus.directive';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, combineLatest, map, merge, Observable, Subject, tap, throwError } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SuppressAnyErrorStateMatcher } from '../../../../shared/material/error-state-matchers/suppress-any-error';
import { CompleteLocalRegistrationRequest, LocalLoginStatusEnum } from '../../models/auth.models';
import { IAlertService } from '../../../../core/services/notifications/alert.service';
import { ExternalLoginComponent } from "../../components/external-login/external-login.component";
import { IConfigurationService } from '../../../../core/services/configuration/configuration.service';
import { TranslocoModule } from '@jsverse/transloco';
import { LanguageSelectComponent } from "../../../i18n/components/language-select/language-select.component";
import { SafeHtmlPipe } from '../../../../shared/pipes/safe-html.pipe';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../services/auth.service';

type LoginStep = "initial" | "code" | "processing" | "profile" | "done";

type LoginState = {
	step: LoginStep;
	email: string | null;
	code: string | null;
	profileToken: string | null;
	returnUrl: string | null;
}

@Component({
	selector: 'app-login',
	standalone: true,
	imports: [
		MatCardModule,
		MatButtonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		AutoFocusDirective,
		AsyncPipe,
		MatProgressBarModule,
		NgIf,
		ExternalLoginComponent,
		TranslocoModule,
		LanguageSelectComponent,
		SafeHtmlPipe,
		MatIconModule
	],
	templateUrl: './login.component.html',
	styleUrl: './login.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPageComponent implements OnDestroy {

	private readonly _authService = inject(AuthService);
	private readonly _alertService = inject(IAlertService);
	private readonly _configService = inject(IConfigurationService);
	private readonly _route = inject(ActivatedRoute);
	private readonly _router = inject(Router);

	private readonly _stateSubject$ = new Subject<LoginState>();
	state$: Observable<LoginState>;

	loading = signal(false);
	blockingTtlSeconds = signal<number | null>(null);
	blockingTtlString: Signal<string | null>;
	blockingIntervalHandle: number | null = null;

	hideCodeInput = signal(true);

	suppressAnyErrorStateMatcher = new SuppressAnyErrorStateMatcher();

	returnUrl: string | null = null;

	readonly loginForm = new FormGroup({
		email: new FormControl<string>('', [Validators.required, Validators.email]),
		code: new FormControl<string>('', [Validators.required, Validators.minLength(5)])
	});

	readonly profileForm = new FormGroup({
		firstName: new FormControl<string>('', [Validators.required]),
		lastName: new FormControl<string>('', [Validators.required]),
	});

	constructor() {

		const params$ = combineLatest([
			this._configService.getConfiguration(),
			this._route.queryParamMap
		]).pipe(
			map(([c, q]) => ({ c, q }))
		);

		this.state$ = merge(
			this._stateSubject$,
			params$.pipe(
				map(v => {
					const result = <LoginState>{ step: "initial" };

					const code = v.q.get("otp");
					const email = v.q.get("email");
					result.returnUrl = v.q.get(v.c.returnUrlParamName);

					if (!email) {
						return result;
					}
					result.email = email;

					if (!code) {
						result.step = "code";
						return result;
					}
					result.step = "processing";
					result.code = code;

					return result;
				})
			)
		).pipe(
			tap(v => {
				this.loginForm.patchValue(v);
				if (v.step === 'code') {
					this.createOtp(v);
				}
				else if (v.step === 'processing') {
					this.processLogin(v);
				}
			})
		);

		effect(() => {
			if (this.loading()) {
				this.loginForm.disable();
			}
			else {
				this.loginForm.enable();
			}
		});

		this.blockingTtlString = computed(() => {
			const seconds = this.blockingTtlSeconds();
			if (!seconds) {
				return null;
			}
			return this.secondsToTimeString(seconds);
		});
	}

	ngOnDestroy(): void {
		this.disposeInterval();
	}

	private disposeInterval() {
		if (this.blockingIntervalHandle) {
			window.clearInterval(this.blockingIntervalHandle);
		}
	}

	private restartInterval() {
		this.disposeInterval();
		this.blockingIntervalHandle = window.setInterval(() => {
			let left = this.blockingTtlSeconds();
			if (!left) {
				return;
			}
			left -= 1;
			if (left <= 0) {
				left = 0;
				this.disposeInterval();
			}
			this.blockingTtlSeconds.set(left);
		}, 1000);
	}

	createOtp(state: LoginState) {
		if (this.loginForm.controls.email.value && this.loginForm.controls.email.valid) {
			this._authService.createLoginOtp(this.loginForm.controls.email.value, state.returnUrl).subscribe(v => {
				this.blockingTtlSeconds.set(v.blockingTtlSeconds);
				this.restartInterval();
			});
		}
	}

	processLogin(state: LoginState) {
		if (this.loginForm.valid) {
			this.loading.set(true);
			this._authService.processLocalLogin(this.loginForm.controls.email.value!, this.loginForm.controls.code.value!).pipe(
				catchError(e => {
					this.loading.set(false);
					return throwError(() => e);
				})
			).subscribe(v => {
				if (v.statusId == LocalLoginStatusEnum.Ok) {
					this.onLoginSucceeded(state);
					return;
				}
				this.loading.set(false);
				if (v.statusId == LocalLoginStatusEnum.WrongCredentials || v.statusId == LocalLoginStatusEnum.GenericError) {
					this._alertService.alertError("Ошибка", "Ошибка аутентификации", 2000);
					return;
				}
				if (v.statusId == LocalLoginStatusEnum.ProfileRequired && v.pendingProfileToken) {
					const next = { ...state };
					next.profileToken = v.pendingProfileToken;
					next.step = 'profile';
					this._stateSubject$.next(next);
					return;
				}

			});
		}
	}

	private secondsToTimeString(totalSeconds: number): string {
		const totalMs = totalSeconds * 1000;
		const result = new Date(totalMs).toISOString().slice(11, 19);
		return result;
	}

	submit(state: LoginState) {
		const next = { ...state };
		if (state.step === 'initial' && this.loginForm.controls.email.valid) {
			next.email = this.loginForm.controls.email.value;
			next.step = 'code';
			this._stateSubject$.next(next);
			//this._router.navigate(["/login"], { queryParams: { email: this.loginForm.controls.email.value }, queryParamsHandling: "merge" });
		}
		if ((state.step === 'code' || state.step === 'processing') && this.loginForm.valid) {
			next.code = this.loginForm.controls.code.value;
			next.step = 'processing';
			this._stateSubject$.next(next);
		}
	}

	submitProfile(state: LoginState) {
		if (this.profileForm.invalid) {
			return;
		}
		const request = this.profileForm.value as CompleteLocalRegistrationRequest;
		request.email = state.email!;
		request.pendingProfileToken = state.profileToken!;

		this.loading.set(true);
		this._authService.completeLocalRegistration(request).pipe(
			catchError(e => {
				this.loading.set(false);
				return throwError(() => e);
			})
		).subscribe(v => {
			this.onLoginSucceeded(state);
		});
	}

	onLoginSucceeded(state: LoginState) {
		if (state.returnUrl) {
			window.location.href = state.returnUrl;
		}
		else {
			this._router.navigate(["/profile"], { queryParamsHandling: "preserve" });
		}
	}

	onExtLogin() {
		this.loading.set(true);
	}

	toggleHideCodeInput(event: MouseEvent) {
		this.hideCodeInput.set(!this.hideCodeInput());
		event.stopPropagation();
	}
}
