import { Component, effect, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PageWrapperComponent } from './shared/components/page-wrapper/page-wrapper.component';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, filter, Observable, tap } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { addSvgIcons } from './shared/material/icons/icon-manager';
import { toObservable } from '@angular/core/rxjs-interop';
import { Language } from './features/i18n/models/i18n.models';
import { LanguageSelectComponent } from "./features/i18n/components/language-select/language-select.component";
import { InternationalizationService } from './features/i18n/services/internationalization.service';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		RouterOutlet,
		PageWrapperComponent,
		NgIf,
		AsyncPipe,
		LanguageSelectComponent
	],
	templateUrl: './app.component.html',
	styleUrl: './app.component.less'
})
export class AppComponent {
	title = 'Fusara.Identity.UI';

	private readonly _oidcService = inject(OidcSecurityService);
	private readonly _i18nService = inject(InternationalizationService);

	private readonly currentLanguage$ = toObservable(this._i18nService.currentLanguage);

	ready$: Observable<[LoginResponse, Language]>;

	constructor() {
		this.ready$ = combineLatest([
			this._oidcService.checkAuth(),
			this.currentLanguage$.pipe(filter(v => !!v))
		]);
		addSvgIcons();
	}
}
