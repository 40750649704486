import { Routes } from '@angular/router';
import { LoginPageComponent } from './features/auth/pages/login/login.component';
import { ProfilePageComponent } from './features/auth/pages/profile/profile.component';
import { isAuthenticatedGuard } from './shared/guards/is-authenticated.guard';

export const routes: Routes = [
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{ path: 'login', component: LoginPageComponent },
	{ path: 'profile', component: ProfilePageComponent, canActivate: [isAuthenticatedGuard] },
];
