<ng-container *transloco="let t; prefix: 'pages.login'">
	<div class="wrapper" *ngIf="state$ | async as state">
		<form *ngIf="state.step !== 'profile'" [formGroup]="loginForm" (ngSubmit)="submit(state)">
			<mat-card appearance="raised" class="login-wrapper">
				<mat-card-header>
					<mat-card-title>{{ t("initial.title") }}</mat-card-title>
				</mat-card-header>
				<div class="progress-wrapper">
					<mat-progress-bar mode="indeterminate" *ngIf="loading()"></mat-progress-bar>
				</div>
				@if(state.step === "initial") {
				<mat-card-content>
					<div class="rowset-wrapper">
						<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
							<mat-label>{{ t("initial.emailLabel") }}</mat-label>
							<input formControlName="email" matInput type="email" [errorStateMatcher]="suppressAnyErrorStateMatcher" [placeholder]="t('initial.emailPlaceholder')" app-autofocus />
							<mat-error>{{ t("initial.emailError") }}</mat-error>
						</mat-form-field>
					</div>
				</mat-card-content>
				<mat-card-actions [align]="'end'">
					<button mat-flat-button color="primary" [disabled]="loginForm.controls.email.invalid || loading()">{{ t("initial.buttonNext") }}</button>
				</mat-card-actions>
				} @else if(state.step === "code" || state.step === "processing") {
				<mat-card-content>
					<div class="rowset-wrapper">
						<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
							<mat-label>{{ t("code.passwordLabel") }}</mat-label>
							<input
								formControlName="code"
								matInput
								[type]="hideCodeInput() ? 'password' : 'text'"
								[errorStateMatcher]="suppressAnyErrorStateMatcher"
								[placeholder]="t('code.passwordPlaceholder')"
								app-autofocus
							/>
							<button type="button" mat-icon-button matSuffix (click)="toggleHideCodeInput($event)">
								<mat-icon>{{ hideCodeInput() ? "visibility_off" : "visibility" }}</mat-icon>
							</button>
							<mat-error>{{ t("code.passwordError") }}</mat-error>
						</mat-form-field>
						<div class="mat-body-medium" [innerHTML]="t('code.information', { email: loginForm.controls.email.value }) | safeHtml"></div>
					</div>
				</mat-card-content>
				<mat-card-actions [align]="'end'">
					<button mat-flat-button color="primary" [disabled]="loginForm.controls.code.invalid || loading()">
						<mat-icon>login</mat-icon>
						{{ t("code.buttonLogin") }}
					</button>
					<button type="button" mat-button color="secondary" (click)="createOtp(state)" [disabled]="blockingTtlSeconds() === null || loading() || (blockingTtlSeconds() ?? 0) > 0">
						{{ t("code.buttonGenerate") }} <span class="mat-label-small" *ngIf="(blockingTtlSeconds() ?? 0) > 0">{{ blockingTtlString() }}</span>
					</button>
				</mat-card-actions>
				}
			</mat-card>
			<div class="divider mat-body-small">{{ t("externalProviders.title") }}</div>
			<app-external-login (login)="onExtLogin()" [disabled]="loading()" />
		</form>
		<form *ngIf="state.step === 'profile'" [formGroup]="profileForm" (ngSubmit)="submitProfile(state)">
			<mat-card appearance="raised" class="login-wrapper">
				<mat-card-header>
					<mat-card-title>{{ t("profile.title") }}</mat-card-title>
					<app-language-select></app-language-select>
				</mat-card-header>
				<div class="progress-wrapper">
					<mat-progress-bar mode="indeterminate" *ngIf="loading()"></mat-progress-bar>
				</div>
				<mat-card-content>
					<div class="rowset-wrapper">
						<mat-form-field appearance="outline">
							<mat-label>{{ t("profile.firstNameLabel") }}</mat-label>
							<input formControlName="firstName" matInput type="text" [placeholder]="t('profile.firstNamePlaceholder')" app-autofocus />
							<mat-error>{{ t("profile.firstNameError") }}</mat-error>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>{{ t("profile.lastNameLabel") }}</mat-label>
							<input formControlName="lastName" matInput type="text" [placeholder]="t('profile.lastNamePlaceholder')" />
							<mat-error>{{ t("profile.lastNameError") }}</mat-error>
						</mat-form-field>
					</div>
				</mat-card-content>
				<mat-card-actions [align]="'end'">
					<button mat-flat-button color="primary" [disabled]="profileForm.invalid || loading()">{{ t("profile.buttonRegister") }}</button>
				</mat-card-actions>
			</mat-card>
		</form>
	</div>
</ng-container>
