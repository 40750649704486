import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[app-autofocus]',
	standalone: true
})
export class AutoFocusDirective implements AfterViewInit {

	constructor(private readonly element: ElementRef<HTMLInputElement>) { }

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.element.nativeElement.focus();
		});
	}
}
